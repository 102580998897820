<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <!-- <img :src="importedImg1" :alt="altText" class="imgItem1" /> -->
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400" >
       <h1>Política de privacidad</h1>
       <!-- <div class="title-line"></div> -->
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/desarrolloorigenesfofor/components/img/4_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`
En cumplimiento a lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares ("LFPDPPP"), sus disposiciones, y demás disposiciones legales aplicables en México (en lo sucesivo denominadas conjuntamente como la Ley), emitimos a su consideración una declaración de privacidad relativa a la regulación legal y tratamiento informado de sus datos personales con el fin de garantizar su privacidad personal y el derecho a la autodeterminación de su información.

1. Identidad y dirección del recopilador de datos
Lea atentamente esta Política de privacidad antes de utilizar nuestros servicios y, si no está de acuerdo con ella o con alguna de sus partes, no acceda a ninguna parte de nuestra plataforma y servicios.

2. Finalidad de la recogida y almacenamiento de datos personales

(1) El objetivo principal de recopilar su información es proporcionarle una experiencia y un servicio más seguros, rápidos, eficientes y ágiles, y ayudarnos a mejorar y perfeccionar eficazmente los servicios que prestamos.

(2) Verificar su identificación o cualquier otra información autorizada que nos facilite.

(3) Analizar y evaluar la admisibilidad de las solicitudes presentadas (incluidos, entre otros, la calificación crediticia, la evaluación del crédito y el análisis de la capacidad de pago) antes de conceder los préstamos.

(4) Para detectar, prevenir y combatir el fraude, el blanqueo de dinero y/o cualquier otro uso ilegal de nuestros servicios.

(5) Proporcionar un sistema de facturación y un método de pago para los productos que adquiera o los servicios que contrate

(6) Gestionar, responder y resolver cualquier cuestión planteada por los usuarios, incluidas, entre otras, todas las consultas, quejas y/o reclamaciones presentadas durante el uso.

(7) Prestar apoyo al servicio de atención al usuario.

(8) Mantener la estabilidad del servicio.

(9) Documentar y resolver los problemas del servicio.

(10) Cumplir las obligaciones legales locales y los requisitos de restricción de información.

(11) Cumplir los requisitos de las leyes y normativas locales aplicables.

(12) El elemento de reconocimiento facial en los datos biométricos faciales personales, que ha confirmado su verdadera identidad, para prevenir el fraude y la suplantación de la información personal y para permitirnos concluir los documentos contractuales necesarios.

3. Información sobre los datos personales recogidos y tratados

Al solicitar un préstamo, los usuarios deben aceptar autorizar los siguientes permisos que obtenemos de su dispositivo:

(1) Información personal básica

Obtenemos la información que nos facilita en sus datos de identificación, incluidos su nombre, sexo, fecha de nacimiento, formación académica, dirección de correo electrónico, vivienda y lugar de residencia. Esta información sólo se utiliza para evaluar la autenticidad del préstamo y predecir el riesgo, y no revelaremos su información sin su permiso. Usted autoriza la carga cifrada de información personal básica en nuestro servidor.

(2) Cámara

Con el fin de proporcionar una forma sencilla y rápida de tomar identificaciones con el fin de ofrecerle una experiencia fácil y sin problemas, por favor permita el acceso a la cámara de la aplicación

(3) Localización

Recopilamos información de localización de su dispositivo, (GPS, IP) para reducir el riesgo asociado a su cuenta, esta información ayuda a tomar mejores decisiones de riesgo de información y simplifica el proceso de conozca a su cliente (kyc)

Los datos recibirán protección mediante cifrado y se cargarán y almacenarán en nuestros servidores a través de una conexión segura.

La información del perfil del fichero es necesaria para detectar fraudes y gestionar riesgos, recopilar y controlar mejor información específica sobre su teléfono, por ejemplo: la configuración del usuario identificador del dispositivo de su teléfono, ya que esta información nos ayuda a identificar de forma exclusiva al usuario para que podamos detectar y evitar que dispositivos no autorizados utilicen su nombre.

4. Datos personales sensibles recogidos

No recabamos datos personales sensibles del titular. Es importante señalar en particular que los elementos mencionados anteriormente son categorías de datos personales.

5. Notas especiales

Todos los datos anteriores no se almacenarán de forma permanente y se borrarán después de su uso.

Al crear una cuenta en nuestra aplicación móvil y aceptar nuestra declaración de privacidad, usted da su consentimiento para que procesemos sus datos personales para los fines descritos anteriormente.

Usted podrá revocar en cualquier momento su consentimiento para el tratamiento de sus datos personales, en cuyo caso sólo podremos continuar con dicho tratamiento de conformidad con las excepciones previstas en la LFPDPPP.

Por último, le informamos que conservaremos sus datos personales durante el tiempo necesario para cumplir con nuestras obligaciones legales y mientras los plazos legales no prevean el inicio o la respuesta a cualquier procedimiento judicial relacionado con la relación jurídica establecida y creada entre usted. Una vez cumplidas las finalidades para las que recabamos, tratamos y almacenamos sus datos, continuaremos bloqueándolos para su posterior cancelación

6. Procedimientos para el ejercicio de los derechos de acceso, rectificación, cancelación y oposición (derechos ARCO)

Usted tiene derecho a conocer qué datos personales tenemos sobre usted, las finalidades para las que los utilizamos y las condiciones aplicables en las que los facilitamos (acceso). Asimismo, tiene derecho a que sus datos personales se corrijan si están desactualizados, son inexactos o incompletos (rectificación); a que se eliminen de nuestros registros o bases de datos cuando consideremos que no están siendo utilizados conforme a los principios, deberes y obligaciones establecidos en la normativa (cancelación); y a oponerse al uso de sus datos personales para una finalidad concreta (oposición).

Para conocer los procedimientos y requisitos para el ejercicio de los derechos ARCO arriba indicados, puede ponerse en contacto con nuestro Departamento de Privacidad en la dirección de correo electrónico, que tramitará las solicitudes de ejercicio de estos derechos y resolverá cualquier duda que pueda tener sobre el tratamiento de sus datos.

En determinadas circunstancias, puede retirar el consentimiento que nos ha dado para tratar sus datos personales. Sin embargo, es importante que considere que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que requiramos seguir tratando sus datos personales debido a ciertas obligaciones legales. Asimismo, usted debe considerar que para ciertos fines, la revocación de su consentimiento implicará que no podamos seguir prestándole los servicios que nos solicitó o que concluya su relación con nosotros. Para retirar su consentimiento, deberá presentar su solicitud por correo electrónico y proporcionar el nombre del titular, datos de contacto y anexar identificación oficial.

7. Fuga de datos y protección de la seguridad

Los datos que recopilamos para su procesamiento y almacenamiento se codifican y controlan de forma segura en nuestra base de datos y no se revelarán a terceros que contengan información de identificación personal (excepto cuando lo exija la ley) y, por lo general, sólo compartiremos su información con terceros de forma anónima. Usted entiende y acepta que podemos transferir y divulgar su información si es necesario o apropiado para los siguientes fines comerciales:

(1) Requisitos legales:

Creemos de buena fe y de buena fe que es necesario revelar sus datos personales para los siguientes fines:

- Para cumplir obligaciones legales.

- Proteger y defender nuestros derechos o propiedad.

- Evitar otras responsabilidades legales.

(2) Requisitos de revisión:

Sobre la base de una revisión de riesgos o cualquier otra necesidad laboral, cualquier información personal relativa a usted cubierta por esta Política de Privacidad que usted haya autorizado podrá ser revelada a:

- Cualquiera de nuestros empleados/socios comerciales/proveedores, incluidos los agentes/contratistas/proveedores de servicios/terceros legales relacionados que nos presten servicios administrativos, de telecomunicaciones, facturación, gestión, auditoría u otros servicios.

- Cualquier agencia de información crediticia/agencia de cobro y/o agencia de crédito relacionada.

- Nuestros empleados o asesores, incluidos auditores, asesores jurídicos y/u otros tipos de asesores.

(3) Proveedores de servicios:

- Podemos contratar a terceras empresas y particulares para que faciliten nuestros servicios (proveedores de servicios como procesadores de pagos de terceros), presten servicios en nuestro nombre, realicen servicios relacionados con los servicios o nos ayuden a analizar el uso que usted hace de nuestros servicios.

- Estos terceros sólo tendrán acceso a sus datos personales si realizan estas tareas en nuestro nombre y están obligados a no divulgarlos ni utilizarlos para ningún otro fin.

8. Opciones y métodos para la utilización o divulgación de datos personales

El titular puede inscribirse en el Registro Público para Evitar la Divulgación (REPEP), a cargo de la Procuraduría Federal del Consumidor (PROFECO), para limitar el uso y divulgación de sus datos personales. Este registro (REPEP) permite al titular inscribirse en una base de datos para restringir llamadas promocionales a números privados (fijos o móviles), de trabajo o correo electrónico, con el fin de proteger la seguridad de su privacidad y evitar molestias ocasionadas por estas llamadas o mensajes enviados.

9. Condiciones y procedimientos para la modificación de la Declaración de Política de Privacidad

Esta Declaración de Privacidad puede ser modificada, cambiada o actualizada para cumplir con nuevos requisitos legales.nuestras propias necesidades para los productos o servicios que ofrecemos; nuestras prácticas de privacidad; cambios en nuestro modelo de negocio, o por otras razones. El propietario puede revisar la declaración de privacidad en cualquier momento en nuestro sitio web o aplicación, incluso después de aceptarla.

10. Consentimiento

Al registrarse en nuestra aplicación, usted acepta que los términos de esta Declaración de Privacidad le otorgan un consentimiento libre, específico, informado y expreso para el tratamiento de sus datos personales.`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: "";
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3700px;
  /* background-color: #FFFFFF; */
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  margin-top: 50px;
 /* margin-left: 490px; */
 color: #FFFFFF;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
/* display: inline-block; */
text-align: center;
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #FFFFFF;
}
.imgBox2{
  /* transform: scale(90%); */
  transform: scale(86%);
  margin: -100px auto 0;
  height: 3200px;
  /* box-shadow:0 3px 8px rgba(0, 0, 0, 0.25); */
}

.title-line{
  width: 270px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #333333FF;
  /* margin: 0 490px; */
  border-radius: 12px;
}
.img1{
  /* margin-top: 50px;
  padding: 0 200px; */
  /* background-color: #333333FF;
  height: 300px; */
}
.img2{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img3{
  background-image: url("@/page/boomcredit/components/img/4_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 50px 100px 300px;
}
</style>